.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main {
	display: grid;
	place-items: center;
	height: 100%;
	background: linear-gradient(
			to bottom,
			var(--color-bg),
			transparent,
			var(--color-bg)
		),
		radial-gradient(circle, transparent 0%, var(--color-bg) 70%);
}

.wrapper {
	position: relative;
	border: 1px solid var(--color-primary);
	border-radius: var(--rounded-md);
	background: hsl(var(--h), 1%, 10%);
	max-width: clamp(20rem, 90%, 40rem);
	box-shadow: var(--shadow-lg);
}

.wrapper::after {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	right: 0;
	z-index: -1;
	height: 100%;
	width: 100%;
	filter: blur(100px);
	background: linear-gradient(95deg, #7baff3, #3f8ef6, #0b2c56, #4e70d0);
	background-size: 200% 200%;
	animation: animateGlow 5s linear infinite;
}

.content {
	margin-top: 1.5rem;
}

.footer {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.5rem;
	background-color: hsla(var(--h), var(--s), 22%, 0.5);
	border-top: 1px solid hsl(var(--h), var(--s), 20%);
	border-radius: 0 0 var(--rounded-md) var(--rounded-md);
	font-size: 0.875rem;
	white-space: pre;
	color: var(--color-grey-700);
}

.footer svg {
	margin-right: 0.25rem;
}

.footer a {
	color: inherit;
	text-decoration: none;
	text-underline-offset: 0.125rem;
}

.footer a:hover {
	color: var(--color-grey-500);
}

.footer svg:has(+ a:hover) {
	color: var(--color-grey-500) !important;
}

.container {
	padding: 2rem 2.25rem;
}

.wrapper li {
	color: var(--color-grey-400);
	line-height: 1.75;
}

.wrapper li:not(:first-child) {
	margin-top: 1rem;
}

.main h1 {
	font-size: 1.75rem;
	font-weight: 600;
}

span.button {
	position: relative;
	display: inline-flex;
	vertical-align: bottom;
	align-items: center;
	justify-content: center;
	gap: 0.375rem;
	padding: 0.375rem 0.675rem;
	margin: 0 0.25rem;
	font-size: 0.875rem;
	border-radius: var(--rounded-sm);
	background-color: hsl(var(--h), var(--s), 20%);
	border: 1px solid hsl(var(--h), var(--s), 22%);
	color: inherit;
	line-height: 1;
	box-shadow: var(--shadow-md);
	cursor: pointer;
	user-select: none;
	transition: all 0.1s;
}

.highlight {
	position: relative;
}

.highlightSelected {
	z-index: 5;
}

.highlightSelected::after {
	opacity: 0.5 !important;
}

.highlight::after {
	opacity: 0;
}

.highlight::after {
	transition: opacity 0.2s;
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	right: 0;
	z-index: -1;
	height: 100%;
	width: 100%;
	filter: blur(15px);
	background: linear-gradient(95deg, #7baff3, #3f8ef6, #0b2c56, #4e70d0);
	background-size: 200% 200%;
	animation: animateGlow 5s linear infinite;
}

span.button:hover {
	background-color: hsl(var(--h), var(--s), 30%);
	border: 1px solid hsl(var(--h), var(--s), 32%);
	color: var(--color-grey-100);
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	padding-bottom: 1.75rem;
}

.header .buttons {
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

.backdrop {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 4;
	pointer-events: none;
	background: hsla(0, 0%, 4%, 0.75);
	transition: opacity 0.2s;
}

@keyframes animateGlow {
	0% {
		background-position: 0% 50%;
	}
	100% {
		background-position: 200% 50%;
	}
}
