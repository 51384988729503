@import url("https://use.typekit.net/isp8ayw.css");

:root {
  --font-base: "Halcom", sans-serif;
  --font-mono: "Fira Code", "Fira Mono", "Roboto Mono", monospace;
  --h: 180;
  --s: 2%;
  --l: 20%;
  --color-bg: rgb(17, 17, 17);
  --color-primary: hsl(var(--h), var(--s), var(--l));
  --shadow-md: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  --shadow-lg: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  --rounded-sm: 0.5rem;
  --rounded-md: 1.5rem;
  --thumb-color: white;
  --thumb-background: black;
}

::-webkit-scrollbar {
  /* background-color: #000; */
  background-color: var(--thumb-background);
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background: var(--thumb-color);
  border-radius: 10px;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
  color: white;
  background-color: var(--color-bg);
}

body {
  min-height: 100%;
  max-width: 100%;
  margin: 0 auto;
  font-family: var(--font-base);
}

input {
  font-family: var(--font-base);
}

pre,
code {
  font-family: var(--font-mono);
}

ul,
li {
  list-style-position: inside;
}

header {
  position: fixed;
  width: 100%;
}

::-webkit-calendar-picker-indicator {
  background-color: #fff;
}

a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.MuiSvgIcon-root {
  color: white;
}
